/* RESET CSS -- RESET CSS -- RESET CSS */
/* RESET CSS -- RESET CSS -- RESET CSS */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
}

*::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

*::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 100px;
}

/* Rimuovo focus e outlines */
a,
a:focus,
a:active,
a:visited,
a:hover,
button,
button:focus,
button:active,
button:visited,
input,
input:focus,
input:active,
input:visited {
    box-shadow: none;
    outline: none;
    text-decoration: none;
}

/* Colori BG */
.bg-blue {
    background-color: #426ed2 !important;
}
.bg-darkblue {
    background-color: #34477e !important;
}
.bg-green {
    background-color: #46d186 !important;
}
.bg-red {
    background-color: #e15366 !important;
}
.bg-orange {
    background-color: #f2863f !important;
}
.bg-grey {
    background-color: #a8a8a8 !important;
}
.bg-darkgrey {
    background-color: #929292 !important;
}
.bg-gradient {
    background: linear-gradient(45deg, #e6f4ff, #ffebee) !important;
}
.shadow-default {
    box-shadow: 0 0 20px -10px #303151;
}
.bg-primary {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

/* Colori testo */
.text-blue {
    color: #426ed2 !important;
}
.text-darkblue {
    color: #34477e !important;
}
.text-green {
    color: #46d186 !important;
}
.text-red {
    color: #e15366 !important;
}
.text-orange {
    color: #f2863f !important;
}
.text-grey {
    color: #a8a8a8 !important;
}
.text-transparent {
    color: transparent;
}

/* Fonts */
@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-variable.ttf');
}
@font-face {
    font-family: 'Lora';
    src: url('../assets/fonts/Lora-Bold.ttf');
}

/* Pesi dei testi */
.text-heading {
    font-family: 'Lora', serif !important;
}
.text-bold {
    font-weight: 600;
}

/* Font size for Text */
A,
ABBR,
ACRONYM,
ADDRESS,
BDO,
BLOCKQUOTE,
BODY,
BUTTON,
CITE,
DD,
DEL,
DFN,
DIV,
DL,
DT,
EM,
FIELDSET,
FORM,
H1,
H2,
H3,
H4,
H5,
H6,
IMG,
INS,
KBD,
LABEL,
LI,
OBJECT,
OL,
P,
SAMP,
SMALL,
SPAN,
SUB,
SUP,
UL,
VAR,
APPLET,
BASEFONT,
BIG,
CENTER,
DIR,
FONT,
HR,
MENU,
PRE,
S,
STRIKE,
TT,
U {
    font-size: 1rem;
}

/* 1rem = 16px */

/* 60px */
h1,
.h1 {
    font-size: 3.5rem;
}

/* 36px */
h2,
.h2 {
    font-size: 2.25rem;
}

/* 32px */
h3,
.h3 {
    font-size: 2rem;
}

/* 26px */
h4,
.h4 {
    font-size: 1.625rem;
}

/* 22px */
.text-bigger {
    font-size: 1.375rem;
}

/* 18px */
.text-big {
    font-size: 1.125rem;
}

/* 14px */
.text-small {
    font-size: 0.875rem;
}

/* 12px */
.text-smaller {
    font-size: 0.75rem;
}

/* misc */
.cursor-pointer {
    cursor: pointer;
}
.opacity-0 {
    opacity: 0 !important;
}
.opacity-1 {
    opacity: 1 !important;
}

// Override Font Awesome
.fa,
.far,
.fas {
    font-family: 'Font Awesome 5 Free' !important;
}
.fa,
.fas {
    font-weight: 900 !important;
}

/* Buttons */
.btn {
    -webkit-appearance: unset;
    appearance: none;
}
.btn:focus,
.btn:active {
    outline: none !important;
}
.btn:not(.btn-default):active,
.btn:not(.btn-default):focus {
    box-shadow: none !important;
}
.btn-default {
    max-width: 100%;
    height: 35px;
    border-radius: 50px;
    border: none;
    text-transform: uppercase;
    padding: 0.4rem 1rem;
    transition: 0.2s ease-in-out;
}
.btn-default:focus,
.btn-default:hover {
    outline: none !important;
    box-shadow: none !important;
    color: #fff !important;
}
.btn-default.disabled,
.btn-default:disabled,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default:disabled:focus,
.btn-default:disabled:hover,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover {
    background-color: #cccccc !important;
    color: #828282 !important;
    pointer-events: none;
}

/* Effetti di hover btns */
.btn-default.bg-blue:focus,
.btn-default.bg-blue:active,
.btn-default.bg-blue:hover {
    background-color: #315097 !important;
}
.btn-default.bg-red:focus,
.btn-default.bg-red:active,
.btn-default.bg-red:hover {
    background-color: #c03749 !important;
}
.btn-default.bg-green:focus,
.btn-default.bg-green:active,
.btn-default.bg-green:hover {
    background-color: #259c5d !important;
}
.btn-default.bg-orange:focus,
.btn-default.bg-orange:active,
.btn-default.bg-orange:hover {
    background-color: #c46c31 !important;
}

/* Dropdown */
button.btn-default[dropdowntoggle]::after {
    border-style: solid !important;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-width: 0.2em 0.2em 0 0 !important;
    display: inline-block;
    height: 12px;
    left: 0;
    bottom: 4px;
    margin-left: 0;
    position: relative;
    transform: rotate(135deg);
    width: 12px;
}

.btn-termina i {
    margin-bottom: 1rem !important;
    font-size: 3.5rem;
}

/* Labels */
.rounded-label {
    border-radius: 30px;
    height: 35px;
    padding: 6px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.h-35 {
    height: 35px !important;
}

.h-40 {
    height: 40px !important;
}

.border-radius-default {
    border-radius: 25px;
}

.border-grey {
    border: 1px solid #ced4da;
}

.borderless-right {
    border-right: 0px;
}

/* Switch buttons */
.switch-buttons {
    height: 40px;
    width: 100px;
}
.switch-buttons .single-button {
    height: 100%;
    width: 50%;
    font-size: 20px;
    transition: 0.3s;
}
.switch-buttons .single-button.switch-button-sx {
    border-radius: 30px 0 0 30px;
}
.switch-buttons .single-button.switch-button-dx {
    border-radius: 0 30px 30px 0;
}

/* Tasto indietro BACK-BUTTON */
.back-button,
.placeholder-space {
    display: block;
    width: 120px;
}

/* Regole generali mat-form-field */
.input-default input,
.select-default {
    height: 44px;
    font-size: 1rem;
    border-radius: 50px;
    padding: 0.325rem 0.75rem;
    border: solid 1px transparent !important;
}
.select-default .mat-select-trigger {
    top: 4px;
}
.mat-form-field-label-wrapper,
.mat-form-field-underline {
    display: none;
}
.input-default input:focus,
.select-default:focus {
    border: solid 1px #eee !important;
}
.input-default input[readonly]:focus {
    box-shadow: 0 0 20px -10px #303151;
}

/* Header Modali */
.modal-close-button > span {
    font-size: 50px;
}

.login-page,
.register-page {
    background: none !important;
}

/* Stilizzazione tooltip */
::ng-deep .bs-tooltip-top {
    padding-top: 0 !important;
    top: -30px;
}
::ng-deep .bs-tooltip-top .tooltip-arrow {
    left: 10px;
}
::ng-deep .bs-tooltip-top .tooltip-inner {
    font-size: 12px;
    text-transform: uppercase;
}

/* Stilizzazione dragged object */
.cdk-drag.cdk-drag-preview.possible-answer-image {
    max-width: 12em !important;
    width: 12em !important;
    max-height: 12em !important;
}

.drop-container {
    border-style: dotted;
}

.todayClass {
    background-color: orange;
}

/*Lavagna virtuale*/
.striscia-caa-wrap exercise-icon {
    overflow: hidden;
}
.horizontal-view .asphi-wrapper {
    width: 250px;
    height: 140px;
}
.vertical-view .asphi-wrapper .asphi-clock-layer,
.horizontal-view .asphi-wrapper .asphi-clock-layer {
    position: absolute !important;
    left: 14px !important;
    height: 91px !important;
    width: auto;
}
.horizontal-view .striscia-caa-wrap .single-exercise {
    overflow-x: scroll;
    display: flex;
}
.horizontal-view .striscia-caa-wrap .single-exercise .single-exercise-caa {
    display: flex;
}
.vertical-view .box-timer {
    padding-top: 130px !important;
}
.vertical-view .box-timer .box-crono {
    text-align: center;
    margin-top: 1rem;
}
.vertical-view .striscia-caa-wrap {
    height: 550px;
    overflow: auto;
    justify-content: center;
    text-align: center;
    margin-top: 3rem;
}
.vertical-view .striscia-caa-wrap .single-exercise .single-exercise-caa {
    display: block;
}
.vertical-view #partecipants .cam-users-wrapper {
    display: flex;
    align-content: center;
    justify-content: center;
}
.vertical-view .cam-users-wrapper .single-cam-user .btn-default {
    margin: 0rem 0.5rem !important;
}
.vertical-view .cam-users-wrapper .single-cam-user:first-child {
    border: inherit !important;
}
.vertical-view .cam-users-wrapper .single-cam-user:last-child {
    border-radius: 0px 24px 24px 0px !important;
}
.vertical-view .cam-users-wrapper .single-cam-user:first-child {
    border-radius: 24px 0px 0px 24px !important;
}
.vertical-view .single-cam-user {
    width: 16em !important;
}
.user-avatar-img {
    height: 90%;
}
.answare-container .trash i.fas:hover {
    cursor: pointer;
}

.didactic-library .btn {
    border-radius: 20px !important;
    height: auto !important;
    min-height: 125px;
}

.didactic-library button {
    font-size: 30px;
}

.didactic-library button i {
    font-size: 60px;
}

.fc-h-event .fc-event-title-container {
    padding: 0.25rem 0.5rem;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end {
    border-radius: 100px;
    background-color: #426ed2;
}

.fc .fc-daygrid-day-number {
    font-size: 25px;
    font-weight: bold;
    color: #34477e;
}

.fc-header-toolbar .fc-toolbar-chunk,
.fc-toolbar-chunk div .fc-toolbar-title {
    display: flex !important;
    align-items: center !important;
}

.fc-toolbar-chunk div .fc-toolbar-title {
    font-weight: bold;
    text-transform: capitalize;
    margin: 0rem 1rem;
}

.fc-toolbar-chunk div:nth-child(2) {
    display: flex;
}

.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-left: 0 !important;
}

/* Riga Ricerca */
.search-bar-wrapper {
    height: 40px;
    border-radius: 20px;
    padding-left: 10px;
    line-height: 1;
}
.search-bar-wrapper > input {
    border-radius: 0;
    border: none;
    background-color: transparent;
    width: calc(100% - 45px);
    margin-right: 5px;
    height: 40px;
    position: relative;
    top: -5px;
}

.search-bar-wrapper > label {
    border-radius: 0;
    border: none;
    background-color: transparent;
    width: calc(100% - 45px);
    margin-right: 5px;
    height: 40px;
    position: relative;
    top: -5px;
}

.search-bar-wrapper > button.start-search {
    border: none;
    width: 40px;
    height: 100%;
    border-radius: 50%;
}

/*Full calendar*/
::ng-deep full-calendar .fc-day-today {
    border: 2px solid #e15366 !important;
    background-color: rgb(66 110 210 / 50%) !important;
}

::ng-deep full-calendar .fc-button {
    border: unset !important;
    border-color: unset !important;
    text-transform: uppercase !important;
    box-shadow: 0 0 20px -10px #303151 !important;
    font-size: 20px !important;
    transition: 0.3s !important;

    -webkit-appearance: unset;
    -moz-appearance: none;
    appearance: none;
}

.fc-button-primary {
    background-color: transparent !important;
    color: gray !important;
    border: inherit !important;
}

::ng-deep full-calendar .fc-header-toolbar .fc-button-group .fc-button.fc-prev-button {
    border-radius: 30px 0 0 30px;
}
::ng-deep full-calendar .fc-header-toolbar .fc-button-group .fc-button.fc-next-button {
    border-radius: 0 30px 30px 0;
}

.fc-toolbar-title,
.fc-col-header-cell-cushion {
    color: #34477e !important;
    font-size: bold !important;
}

.fc-scrollgrid th,
.fc-theme-standard td {
    border: 1px solid #eee !important;
}

.fc-theme-standard th {
    border-top: 1px solid transparent !important;
    border-bottom: 1px solid transparent !important;
}

table.fc-scrollgrid {
    border: none !important;
}

td .fc-daygrid-event:first-child {
    margin-top: 1rem;
}

.fc-daygrid-event {
    white-space: inherit !important;
    text-transform: uppercase !important;
    border-radius: 20px !important;
    margin-bottom: 1rem !important;
    max-height: 300px !important;
}

.fc-daygrid-event .col {
    text-align: center !important;
}

.fc-daygrid-event .col img {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    border-radius: 500px !important;
    height: 120px !important;
    width: 120px !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0 !important;
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: #fef4f6 !important;
}

.modal-content {
    border: inherit;
    border-radius: 20px;
    padding: 10px;
}

.modal-body .col .row {
    font-family: 'Lora', serif !important;
}

.modal-body .col .img-thumbnail {
    padding: 0 !important;
}

.fc-h-event .fc-event-title-container {
    padding: 0.5rem;
}

@media screen and (max-width: 1424px) {
    .vertical-view .single-cam-user {
        width: 13em !important;
    }
}

@media screen and (max-width: 1324px) {
    .vertical-view .single-cam-user {
        width: 11em !important;
    }
}

@media screen and (max-width: 1224px) {
    .vertical-view .single-cam-user {
        width: 10em !important;
    }
    .fc-daygrid-event .col img {
        height: 90px !important;
        width: 90px !important;
    }
}

@media screen and (max-width: 1124px) {
    .vertical-view .single-cam-user {
        width: 9em !important;
    }
}

/*iPad Mini landscape*/
@media screen and (max-width: 1024px) {
    .vertical-view .single-cam-user {
        width: 8.5em !important;
    }
}

.br-10 {
    border-radius: 10px !important;
}

.color-dark-blue {
    color: darkblue;
}

.color-light-blue {
    color: blue
}

.pr-0 {
    padding-right: 0px !important;
}

.align-right {
    text-align: right;
}