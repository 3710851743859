// @import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
// @import '~@angular/cdk/overlay-prebuilt.css';
@import "~@ng-select/ng-select/themes/default.theme.css";

@media (min-width: 576px) {
    .nav-user-menu .user-image {
        float: none;
        line-height: 10px;
        margin-top: -8px;
    }
}
.nav-user-menu .user-image {
    border-radius: 50%;
    float: left;
    height: 2rem;
    margin-top: -4px;
    width: 2rem;
}
.form-group.required .col-form-label:after {
    color: #d00;
    content: '*';
    position: absolute;
    margin-left: 3px;
}

/* Sweet alert */
.swal2-title {
    display: inline-block !important;
}
body.swal2-height-auto {
    height: inherit !important;
}
.swal2-popup.swal2-toast {
    padding: 10px 12px !important;
    display: block !important;
}
.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
    text-align: left !important;
    margin: 0 !important;
}
.swal2-popup.swal2-toast .swal2-title {
    margin-bottom: 4px !important;
}
